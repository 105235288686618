@font-face {
  font-family: myFirstFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Regular.ttf);
}


@font-face {
  font-family: myFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
}


@font-face {
  font-family: mySpanFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf);
}



.main {
  background-color: black;
}

body{
  font-family: myFirstFont;
}

.landing-page {
  
  color: #ede9e9;
  margin-top: 70px;
}

.video-container {
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.image-text-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  max-width: 1200px;
}

.content-text h1 span {
  font-family: mySpanFont;
  font-weight: 100;
}

.content-text{
  display: flex;
  flex-direction: column;
}

.cre1{
  color: #ff7600 ;
}


.cre2{
  color: #2f52dd ;
}


.cre3{
  color: #4ebe52 ;
}


.cre4{
  color: #8ea5cf ;
}



.image-text-container img {
  width: 50%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-text-container video {
  width: 50%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-text {
  width: 50%;
  padding: 20px;
}

.content-text button{
  width: 30%;
  justify-self: center;
}

.reverse {
  flex-direction: row-reverse;
}


.lp1{
  background-color: #ff7600; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded borders */
  padding: 10px 20px; 
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-left:  220px;
  font-family: myFont;
}

.lp2{
  background-color: #4ebe52; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded borders */
  padding: 10px 20px; 
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-left: 220px;
  font-family: myFont;
}

.lp3{
  background-color: #2f52dd; /* Button color */ 
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded borders */
  padding: 10px 20px; 
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-left:  220px;
  font-family: myFont;
}


.lp4{
  background-color: #8ea5cf ; /* Button color */ 
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded borders */
  padding: 10px 20px; 
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-left:  220px;
  font-family: myFont;
}


/* Video Slider */
/* Add these styles to LandingPage.css */
.video-slider {
position: relative;
margin-bottom: 20px; /* Adds space below the slider */
}

.slider-container {
overflow-x: scroll;
scroll-behavior: smooth; /* Ensures smooth scrolling */
white-space: nowrap;
width: 100%;
}

.slider {
display: flex;
align-items: center;
}

.slider-video {
width: 200px; /* Adjust size as needed */
margin-right: 10px; /* Adds space between videos */
}

button.prev, button.next {
position: absolute;
top: 50%;
transform: translateY(-50%);
background-color: rgba(0, 0, 0, 0.5);
color: white;
border: none;
cursor: pointer;
padding: 10px;
z-index: 1;
}

button.prev {
left: 0;
}

button.next {
right: 0;
}

/* Responsive Media Queries */

/* Tablets */
@media (max-width: 1024px) {
  .video-container {
      height: 500px;
  }

  .image-text-container {
      flex-direction: column;
  }

  .image-text-container img, .content-text {
      width: 100%;
  }


  .image-text-container video, .content-text {
    width: 100%;
}

  .content-text {
      padding: 10px;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .landing-page {
      margin-top: 50px;
  }

  .video-container {
      height: 300px;
  }

  .slider-video {
      width: 150px;
      margin-right: 5px;
  }

  button.prev, button.next {
      padding: 5px;
  }

  .lp1{
    font-size: small;
    margin-left:  130px;
  }

  .lp2{
    font-size: small;
    margin-left:  130px;
  }

  .lp3{
    font-size: small;
    margin-left:  130px;
  }

  .lp4{
    font-size: small;
    margin-left:  130px;
  }
}

@media (max-width: 480px) {
  .video-container {
      height: 200px;
  }

  .image-text-container {
      flex-direction: column;
  }

  .image-text-container img, .content-text {
      width: 100%;
  }

  .content-text {
      padding: 5px;
  }

  button.prev, button.next {
      padding: 3px;
  }
}


