.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 10px; /* Added padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.question-text {
  margin: 0;
  font-size: 18px;
  color: #333;
  flex: 1;
  padding-right: 10px; /* Added padding to separate text from input */
}

.upload-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc; /* Added border for better visibility */
  border-radius: 4px; /* Rounded corners for better appearance */
  width: 200px; /* Fixed width for consistency */
}

.submit-button {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .question-container {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: stretch; /* Ensure full width for children */
    padding: 0; /* Remove padding on smaller screens */
  }
  
  .question-text {
    margin-bottom: 10px; /* Space between text and input */
    font-size: 16px; /* Slightly smaller font size for mobile */
  }

  .upload-input {
    width: 100%; /* Make input full-width on small screens */
    font-size: 14px; /* Slightly smaller font size for mobile */
  }
  
  .submit-button {
    width: 100%; /* Make button full-width on small screens */
    font-size: 16px; /* Slightly smaller font size for mobile */
  }
}
