.manage-tshirt-quantities {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .add-product-section {
    margin-bottom: 20px;
  }
  
  .new-product-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .add-product-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .add-product-button:hover {
    background-color: #0056b3;
  }
  
  .sizes-section,
  .product-list {
    width: 100%;
    border-collapse: collapse;
  }
  
  .product-section {
    margin-bottom: 20px;
  }
  
  .product-section h3 {
    margin: 0;
    padding: 10px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
  }
  
  .sizes-section {
    display: table;
    width: 100%;
  }
  
  .size-input-group {
    display: table-row;
  }
  
  .size-label,
  .size-input {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .size-label {
    text-align: right;
    padding-right: 10px;
  }
  
  .size-input {
    width: 100px;
    padding-left: 10px;
  }
  