.profile-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.upper-half {
  height: 50vh; /* 50% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
}

.profile-image {
  display: none; /* Hide the img element, we'll use the background image instead */
}

.profile-details {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  padding-top: 20px;
}

.profile-details p {
  font-size: 18px;
  margin: 10px 0;
}

.profile-details a {
  color: blue;
  text-decoration: none;
}

.profile-details a:hover {
  text-decoration: underline;
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #007bff;
  font-size: 1.5rem;
}
