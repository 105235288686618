body {
  margin: 0;
  padding: 0;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); 
  width: auto;
  background-color: #f5f5f5;
  margin-top: 60px;
}

.signupform {
  background: white;
  padding: 0 30px 30px 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.signupform h2 {
  margin-bottom: 20px;
}

.signupform label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.signupform input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.signupform button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.signupform button:hover {
  background-color: #0056b3;
}

.signupform p {
  margin-top: 15px;
  text-align: center;
}

.signupform span {
  display: block;
  margin-top: 15px;
  color: red;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.success-message {
  color: green;
  font-size: 1rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .signup-container {
    padding: 10px;
  }

  .signupform {
    padding: 20px;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .signupform {
    padding: 15px;
  }

  .signupform h2 {
    font-size: 20px;
  }

  .signupform input {
    padding: 8px;
  }

  .signupform button {
    padding: 8px;
    font-size: 14px;
  }
}
