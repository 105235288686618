.Footer {
    background-color: #282c34; /* Dark background color */
    color: #ffffff; /* White text color */
    text-align: center; /* Center the text */
    padding: 20px 0; /* Padding at the top and bottom */
    position: fixed; /* Fix the footer at the bottom of the page */
    left: 0;
    bottom: 0;
    width: 100%; /* Make sure it spans the entire width of the page */
    font-family: 'Arial', sans-serif; /* Font styling */
    font-size: 14px; /* Font size */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .Footer p {
    margin: 0; /* Remove default margin */
  }
  