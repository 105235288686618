.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .product-title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .product-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .product-description {
    font-size: 1em;
    max-width: 800px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .product-title {
      font-size: 1.5em;
    }
  
    .product-description {
      font-size: 0.9em;
    }
  }
  