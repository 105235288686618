.c-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: row;
  margin-top: 200px;

}

.c-image-container {
  position: relative;
  margin: 20px 0;
  padding: 20px;
}

.image {
  display: block;
  width: 400px;
  height: 250px;
  object-fit: cover;
}

.c-text { /* Updated from .text to .c-text to match HTML */
  text-align: center;
  margin-top: 10px;
  font-size: 30px; /* Increased text size */
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

@media (max-width: 768px) {
  .c-container {
    flex-direction: column;
    margin-top: 100px;
  }

  .c-image-container {
    margin: 0 20px;
  }

  .c-text {
    font-size: 28px; /* Adjust text size for larger screens */
  }
}
