/* General styles for the product list */
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 80px; /* Ensures the list is below the header */
}

/* Product card styles */
.product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    text-align: center;
    flex: 1 1 calc(33.333% - 40px); /* 3 cards per row on desktop */
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
}

.product-name {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-description {
    font-size: 0.9em;
    color: #555;
}

/* Responsive styles */
@media (max-width: 900px) {
    .product-card {
        flex: 1 1 calc(50% - 40px); /* 2 cards per row on tablet/phone */
    }
}

@media (max-width: 600px) {
    .product-card {
        flex: 1 1 calc(100% - 40px); /* 1 card per row on smaller screens */
    }
}
