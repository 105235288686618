/* Container styles */
.dashboard-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
.dashboard-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Greeting styles */
.greeting-container {
  margin-bottom: 20px;
}

/* Filter styles */
.filter-container {
  margin-bottom: 20px;
  text-align: center;
}

/* Select dropdown styles */
.filter-container select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.filter-container select:hover {
  background-color: #f9f9f9;
}

/* Card container styles */
.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px; /* Adds space between cards */
}

/* Card styles */
.card {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  font-family: myFont;
}

.card-value{
  font-family: myFont;
}

@font-face {
  font-family: myFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
}

.d1{
  font-family: myFont;
}


.card-value {
  display: block;
  margin-top: 10px;
  font-size: 24px;
  color: #4CAF50; /* Change color as needed */
}

/* Chart container styles */
.chart-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px; /* Adds space between charts */
}

/* Individual chart styles */
.chart {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
}

.chart:hover {
  transform: translateY(-5px);
}

/* Ensure charts have a consistent height */
.chart canvas {
  max-width: 100% !important;
  max-height: 300px !important; /* Adjust height as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }

  .card-container .card:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

 .card .card:nth-child(1) .card-title {
  justify-content: center; /* Vertically centers the content */
 }

  .card-container .card:nth-child(2),
  .card-container .card:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: auto;
  }

  .chart-container {
    flex-direction: column;
    align-items: stretch;
  }

  .chart {
    margin-bottom: 20px;
  }
}

h4 {
  text-align: center;
}
