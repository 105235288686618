.design-upload-container {
  display: flex;
  justify-content: space-between;
  padding-top: 55px;
  gap: 20px;
  background-color: #fff !important;
}

.form-wrapper {
  flex: 1;
  min-width: 400px;
  min-height: 80vh;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-wrapper h2 {
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
}

.desktop-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.submit-button{
  display: none;
}

.left-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  padding: 20px;
  /* border: 2px solid red; */
  max-height: 100vh;
}


/* 
 .view-and-customize select {
  position: absolute;
  top: 20px; 
  left: 20px; 
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 0; 
}  */



.view-select {
  padding: 8px 12px;
  margin-top: 6.5px;
  font-size: 1.0rem;
  border-radius: 4px;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.preview-controls {
  position: absolute;
  display: flex;
  gap: 10px; 
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  top: 50px; /* Adjust the top offset as needed */
  margin-top: 20px;
}

.preview-controls button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.preview-controls button:hover {
  color: #007bff;
}



/*  toggle button style preview and edit */

.toggle-buttons {
  display: flex;
  align-items: center;
  /* background-color: #f1f1f1; */
  border-radius: 30px;
  margin-bottom: 10px;
  /* border: 2px solid red; */
  /* overflow: hidden; */
}

.toggle-button {
  flex: 1;
  padding: 5px 10px;
  border: 1px solid black;
  background-color: transparent;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button i {
  margin-right: 8px;
}

.toggle-button.active {
  background-color: black;
  color: white;
}

.toggle-buttons .toggle-button:first-child {
  border-radius: 30px 0 0 30px;
}

.toggle-buttons .toggle-button:last-child {
  border-radius: 0 30px 30px 0;
}

.toggle-buttons .toggle-button:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.1);
}



/* Base styles for both desktop and mobile */

canvas {
  /* max-width: 100%; */
  height: auto;
  display: block;
  
}


.drawer-header, .close-drawer , .customize-button{
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  color: black;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #f0f0f0;
}

.custom-file-upload:active {
  background-color: #e0e0e0;
}

.view-and-customize {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-bottom: 15px; */
  width: 100%; /* Ensure full width for centering */
  text-align: center;
  /* border: 2px solid brown; */
}

/* canvas{
  touch-action: manipulation;
} */


@media (max-width: 768px) {
 .drawer-header, .close-drawer , .customize-button{
    display: block !important;
  }

  .submit-button{
    display: block;
  }

  /* .design-upload-container {
    display: flex;
    flex-direction: column;
    max-width: 100vh;
    background-color: #fff !important;
    border: 2px solid blue;
  } */
 

  .form-wrapper {
    flex: 1;
    min-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


  .drawer {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
  }

  .drawer.open {
    left: 0;
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .close-drawer {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .submit-button {
    /* position: fixed; */
    /* bottom: 20px; */
    background-color: #007bff;
    color: white;
    border: none;
   padding: 15px;
    border-radius: 15px;
   width: 80%; 
    cursor: pointer;
    font-size: 1.1rem;
  }

  .submit-button:hover {
    background-color: #0056b3;
  }

    /* Ensure the container is centered */
    .left-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* padding: 20px; */
    max-width: 100vh;
     max-height: 100vh;
      background-color: #fff;
      /* border: 2px solid purple; */
      overflow: hidden;
    }
  
    /* Center and arrange the "view-and-customize" controls */
    .view-and-customize {
      display: flex;
      justify-content: center;
      align-items: center;
      /* gap: 10px; */
      /* margin-bottom: 15px; */
      width: 100%; /* Ensure full width for centering */
      text-align: center;
      justify-content: space-around;
      /* border: 2px solid brown; */
    }
  
    /* .view-and-customize select,
    .view-and-customize .customize-button {
      padding: 8px 12px;
      font-size: 14px;
    } */

    .customize-button {
      background-color: #1954eb;
      color: #fff;
    }
  
    

    /* Ensure there's enough space for the preview controls */
    .preview-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-bottom: 10px; /* Adjusted to add more space below the controls */
      width: 100%;
      margin-top: 10px;
      border: 2px solid green;
    }
  
    .preview-controls button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      color: #333;
      transition: color 0.3s ease;
    }
  
    .preview-controls button:hover {
      color: #007bff;
    }
  
    /* Center the mockup canvas */
    .mockup {
      /* display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9; */
      /* padding: 20px;  */
      /* border-radius: 8px; */
      /* margin-bottom: 20px; */
      /* margin-left: 20px;
      margin-right: 20px; */
      /* width: 100%; */
    }
  
    .mockup canvas {
      /* max-width:90%;
      padding-left: 20px;
      height: auto; */
      /* margin-left: 20px;
      margin-right: 20px; */
      /* border: 2px solid green; */

      height: auto;
      display: block;
    }
  
 /* canvas{
  pointer-events: none; Its working
 } */


  }
  
