.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    padding: 0 20px;
  }
  
  .forgot-password-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .forgot-password-form h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .forgot-password-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .forgot-password-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .forgot-password-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .forgot-password-form button:hover {
    background-color: #0056b3;
  }
  
  .forgot-password-form p {
    margin-top: 20px;
    color: #d9534f;
    font-size: 14px;
  }
  
  /* Responsive CSS for mobile */
  @media (max-width: 600px) {
    .forgot-password-container {
      padding: 0 10px;
    }
  
    .forgot-password-form {
      padding: 15px;
    }
  
    .forgot-password-form h2 {
      font-size: 20px;
    }
  
    .forgot-password-form button {
      font-size: 14px;
      padding: 8px;
    }
  }
  