/* General styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px; /* Adjusted padding */
  background-color: #000;
  height: 60px; /* Set a specific height for the header */
  position: fixed;
  top: 0; /* Ensure it sticks to the top */
  left: 0; /* Ensure it sticks to the left */
  width: 100%; /* Ensure the header spans the full width */
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.logo img {
  height: 60px; /* Reduced height */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-right: 30px;
}

nav ul li {
  margin-left: 10px; /* Adjusted margin between menu items */
}

nav ul li a {
  text-decoration: none;
  color: #fff;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon span {
  height: 3px;
  width: 25px;
  background-color: #fff; /* Set icon color to white */
  margin: 4px 0;
  transition: 0.4s;
}

.profile-icon {
  margin-left: 10px;
}

.profile-icon img {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%; /* Make it round */
}

.li-sign,
.li-log {
  background-color: #7702f4; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded borders */
  padding: 10px 20px; /* Padding for a better look */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
}

/* Optional: Add hover effect */
.li-sign:hover,
.li-log:hover {
  background-color: #5d02c1; /* Darker shade for hover effect */
}

.hi1{
  font-family: myfont;
}

/* Responsive styles */
@media (max-width: 768px) {
  .logo {
    flex: 1;
    text-align: center;
    padding-right: 10px;
  }

  nav {
    position: fixed;
    left: -250px; /* Move off-screen to the left */
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    padding-top: 60px;
    transition: 0.3s;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  nav ul li {
    margin: 20px 0;
  }

  nav ul li a {
    color: #000;
    font-size: 20px;
  }

  .menu-icon {
    display: flex;
    order: -1; /* Move the menu icon to the left */
  }

  .menu-icon.active span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.active span:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.active span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  nav.active {
    left: 0; /* Bring the menu into view */
  }

  .menu-icon.active {
    position: absolute;
    top: 15px;
    right: 160px; /* Place the close button on the right side of the open menu */
    background-color: #fff; /* Background color for the close button */
    padding: 10px; /* Add padding around the close button */
    border-radius: 50%; /* Make the close button circular */
  }

  .menu-icon.active span {
    background-color: #000; /* Change close icon color to black */
  }

  .profile-icon.mobile {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .profile-icon.desktop {
    display: none;
  }
}

@media (min-width: 769px) {
  .profile-icon.mobile {
    display: none;
  }

  .profile-icon.desktop {
    display: block;
  }
}

/* Profile Icon Styles */
.profile-icon {
  position: relative;
  cursor: pointer;
  padding-right: 20px;
}

.profile-icon img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

/* Profile Dropdown Styles */
.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  }
  
  .profile-dropdown a,
  .profile-dropdown button {
  padding: 10px 20px;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  }
  
  .profile-dropdown a
  ,
  .profile-dropdown button
  {
  background-color  : #f0f0f0;
}

.profile-dropdown button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: #333;
}

.profile-dropdown button:hover {
  background-color: #f0f0f0;
}

.profile-dropdown a {
  text-decoration: none;
  color: #333;
}

.profile-dropdown a:hover {
  background-color: #f0f0f0;
}

/* Adjustments for header alignment */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Add padding to the profile icon on the desktop */
.profile-icon.desktop {
  margin-right: 20px; /* Adjust margin as needed */
}

