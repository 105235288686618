/* AdminBTT.css */

.admin-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .responses-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responses-table th,
  .responses-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .responses-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .responses-table a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .responses-table a:hover {
    text-decoration: underline;
  }
  