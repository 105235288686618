/* designs.css */

/* Common styles */
.container {
  padding: 20px;
}

/* Add Design Button */
.add-design-btn {
  float: right;
  margin-bottom: 10px;
}

/* View Toggle */
.view-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.view-button {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
  border: none;
  background: transparent;
  color: #333;
}

.view-button.active {
  color: #007bff; /* Active icon color */
}

/* Table View */
.designs-table {
  width: 100%;
  border-collapse: collapse;
}

.designs-table th, .designs-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.designs-table th {
  background-color: #f4f4f4;
}

/* Grid View */
.designs-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.design-item {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  width: calc(33.333% - 20px); /* Three items per row */
  box-sizing: border-box;
}

.design-item h3 {
  margin-top: 0;
}

.mockup-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.mockup-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .design-item {
    width: calc(50% - 20px); /* Two items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .design-item {
    width: 100%; /* Full width on small screens */
  }

  .mockup-image {
    width: 100px;
    height: 100px;
  }

  .designs-table th, .designs-table td {
    font-size: 14px; /* Smaller text on small screens */
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
}