/* Container styling */
.orders-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .orders-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Table styling */
  .orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .orders-table th,
  .orders-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .orders-table th {
    background-color: #007bff;
    color: white;
  }
  
  .orders-table td {
    background-color: white;
  }
  
  .orders-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .status-chip {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  
  .status-pending {
    background-color: #f0ad4e;
  }
  
  .status-paid {
    background-color: #5bc0de;
  }
  
  .status-voided {
    background-color: #d9534f;
  }
  
  .status-unpaid {
    background-color: #d9534f;
  }
  
  /* Responsive table */
  @media (max-width: 768px) {
    .orders-container {
      padding: 10px;
    }
  
    .orders-table th,
    .orders-table td {
      padding: 8px;
    }
  
    .orders-table th {
      font-size: 14px;
    }
  
    .orders-table td {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .orders-table th,
    .orders-table td {
      padding: 6px;
    }
  
    .orders-table th {
      font-size: 12px;
    }
  
    .orders-table td {
      font-size: 10px;
    }
  }
  
  