
.😊edit-profile {
  padding: 30px;
}

.😊edit-profile-container {
  max-width: 800px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.😊edit-profile-heading {
  text-align: center;
  margin-bottom: 20px;
}

.😊loading-text, .😊pending-approval-text, .😊no-user-text {
  text-align: center;
  font-size: 18px;
  color: #333;
}

.😊edit-profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.😊profile-image-section {
  text-align: center;
  margin-bottom: 20px;
}

.😊profile-image-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.😊profile-image-input {
  display: block;
  margin: 0 auto;
}

.😊profile-image-preview {
  margin-top: 10px;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.😊edit-profile-form {
  width: 100%;
  max-width: 600px;
}

.😊form-label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.😊form-input, .😊form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.😊form-textarea {
  height: 100px;
  resize: vertical;
}

.😊submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.😊submit-button:hover {
  background-color: #0056b3;
}


@media (max-width: 600px) {
  .😊edit-profile-content {
    flex-direction: column;
  }

  .😊profile-image-section {
    order: 1;
  }

  .😊edit-profile-form {
    order: 2;
  }

  .😊form-submit-section {
    order: 3;
  }
}
