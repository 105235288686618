
  
  .coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .coming-soon-title {
    font-size: 4rem;
    color: #222;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .coming-soon-description {
    font-size: 1.5rem;
    color: #666;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .coming-soon-title {
      font-size: 2.5rem;
    }
    
    .coming-soon-description {
      font-size: 1.2rem;
    }
  }
  